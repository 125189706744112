<template>
  <div ref="wrapper">
    <div class="search" v-if="$store.state.userAgent === 'Mobile'">
      <div class="fake-input" @click="goSearch">
          <Swiper :lists="swiperList"/>
      </div>
      <img src="../assets/images/search.png" alt="" width="17" height="17" class="searchImg">
      <router-link to="create" v-if="isLogin" class="createImg">
        <img src="../assets/images/create.png" alt="" width="32" height="32" >
      </router-link>
      <router-link to="login" v-else class="loginImg">
        <img src="../assets/images/login.png" alt="" width="35" height="35" >
      </router-link>
    </div>
    <!-- h5横向标签列表 -->
    <div class="classify" v-if="$store.state.userAgent === 'Mobile'">
        <div :class="[listsType === label.id ? 'label-focus' : 'label']" @click="getHotData(label.id)" v-for="label in articleLabel" :key="label.id">{{ label.name }}</div>
        <div>&ensp;&ensp;</div>
    </div>
    <!-- PC端横向标签列表 -->
    <div class="classify-pc" v-else>
      <div class="row">
          <div :class="[listsType === label.id ? 'label-focus' : 'label']" @click="getHotData(label.id)" v-for="label in articleLabelPC" :key="label.id">{{ label.name }}</div>
          <div class="label" style="margin-right:0px;cursor:not-allowed">更多</div>
          <div>&ensp;&ensp;</div>
      </div>
      <div style="width: 400px;"></div>
    </div>
    <!-- h5文章列表 -->
    <div v-if="$store.state.userAgent === 'Mobile'">
      <Bscroll @refresh="refresh" ref="bscroll" style="width:100%">
        <div class="home_wrap" v-show="!$store.state.loading">
          <Card :lists="lists" @SaveScrollTop="SaveScrollTop" :showAvatar="true"/>
        </div>
        <div :class="[$store.state.userAgent === 'Mobile' ? 'load-more': 'load-more-bottom']"  v-if="total > lists.length && lists.length > 10">正在加载中...</div>
        <div :class="[$store.state.userAgent === 'Mobile' ? 'load-more': 'load-more-bottom']" v-else-if="total === lists.length && !$store.state.loading">已经到底部啦！</div>
      </Bscroll>
    </div>
    <!-- PC端文章列表 -->
    <div style="display:flex;width: 1150px; margin:0 auto;" v-else>
      <Bscroll @refresh="refresh" ref="bscroll" style="width:100%">
        <div class="home_wrap" v-show="!$store.state.loading">
          <Card :lists="lists" @SaveScrollTop="SaveScrollTop" :showAvatar="true"/>
        </div>
        <div :class="[$store.state.userAgent === 'Mobile' ? 'load-more': 'load-more-bottom']"  v-if="total > lists.length && lists.length > 10">正在加载中...</div>
        <div :class="[$store.state.userAgent === 'Mobile' ? 'load-more': 'load-more-bottom']" v-else-if="total === lists.length && !$store.state.loading">已经到底部啦！</div>
      </Bscroll>
      <!-- PC端网站右侧栏内容 -->
      <div style="margin: 123px 0 0 20px; width: 400px;" v-if="$store.state.userAgent === 'PC' && !$store.state.loading">
        <div class="card">
            <div class="title">精选文章</div>
            <div class="wrap">
                <div class="panel" v-for="(list, index) in findLists" :key="index" @click="handleClick(list.id)">
                    <div class="span">
                        <!-- <img src="../assets/images/hot-fill.png" alt="" width="25" height="25"> -->
                        <span>{{ list.text }}</span>
                    </div>
                    <!-- <img src="../assets/images/enter.png" alt="" width="20" height="20"> -->
                </div>
            </div>
        </div>
        <div style="text-align: left;">
          <img :src="qrCode.souyisou" alt="" width="288px">
        </div>
        <!-- 网站备案信息 -->
        <div class="beian">
            <div class="copyright">
                <div class="logo">
                  <img src="../assets/images/copyright-icon.png" alt="" width="20" height="20">
                  <span>2023 小天逻辑</span>
                </div>
            </div>
            <div class="row">
              <img src="../assets/images/beian.png" alt="" width="18" height="18">
              <a href="http://beian.miit.gov.cn/">{{ beianId }}</a>
            </div>
        </div>
        <div class="fixed-button" v-show="showScrollTopBtn">
          <div class="default-scroll-top" id="scroll-top-button" @click="goScrollTop">
            <img src="../assets/images/scroll-top.png" alt="" width="20px">
          </div>
        </div>
      </div>
    </div>
    <Main v-if="$store.state.userAgent === 'Mobile' && appId !== 'yingjing'" @resetScrollTop="resetScrollTop"></Main>
    <div v-if="$store.state.userAgent === 'Mobile' && !showScrollTopBtn && !$store.state.loading && isFocusAIGC" class="chat-robot">
      <router-link to="/chat">
        <img src="../assets/images/chat-robot.png" alt="" width="65" height="65">
      </router-link>
    </div>
    <div class="login-reminder" v-show="showLoginReminder">
        <div class="tips">
          <!-- <div @click="closeLoginReminder" class="cancel">✕</div> -->
          <div class="cancel"></div>
          <div class="text">登陆后可探索更多内容</div>
        </div>
        <router-link to="login" class="login">
          <div class="login-buttton">登录</div>
        </router-link>
    </div>
  </div>
</template>

<script>
import Card from '../components/card/index.vue';
import { queryAllArticle, wxSdk, createUserStas, getIP, getOpenId, queryAllLabel } from '@/api/api'
import Swiper from '../components/swiper/index.vue';
import Bscroll from '../components/bscroll/index.vue';
import Main from '../components/footer/Main.vue'
import wx from "weixin-js-sdk";
const seo = require('../../config/index')
import { debounce } from 'lodash'

export default {
  name: 'Home',
  components: {
      Card,
      Swiper,
      Bscroll,
      Main,
  },
  created(){
    this.getData(this.limit, 'hot', 'all')
    // 验证token是否存在，还需要调接口验证是否有效
    if(localStorage.getItem('token')){
      this.isLogin = true
    }
    this.$bus.$on('logout',this.logout)
    this.$bus.$on('clearFlag',this.clearFlag)
  },
  mounted(){
    // 上传访客数据，仅第一次进入页面触发
    getIP({}).then((res)=>{
      this.$axios.get('https://api.vore.top/api/IPdata?ip='+ res.data.data).then(result=>{
        let params = {}
        params.area = result.data.adcode.p
        params.ip = res.data.data
        createUserStas(params)
      })
    })
    this.initLabel() // 初始化标签
    if(window.location.href.includes('code=')){
      let code = window.location.href.split('code=')[1].split('&')[0]
      getOpenId({ code: code }).then(res=>{
        localStorage.setItem('openid', res.data.openid)
      })
    }
  },
  // 缓存页面用deactivated钩子回收定时器
  deactivated(){
    document.removeEventListener('scroll', this.handleScroll);
    clearTimeout(this.scrollTime)
    window.removeEventListener("popstate", this.myBack, false); 
  },
  watch:{
    '$route': function(newVal, oldVal){
      if(oldVal.path === '/verify' && localStorage.getItem('token')){
        this.isLogin = true
      } else if(oldVal.path === '/create' || oldVal.path === '/search'){
        // 发布文章后更新首页数据
        this.lists = []
        this.$nextTick(()=>{
          this.getData(this.limit, this.listsType, this.label)
        })
      } else if(oldVal.path === '/login'){
        this.showLoginReminder = false
      }
    }
  },
  methods:{
    getData(limit, type, label){
      if(type === 'new'){
        this.listsType = 'new'
      } else if(label === 'all' && type === 'hot'){
        this.listsType = 'hot'
      } else {
        this.listsType = label
      }
      this.label = label ==='all' && type === 'new' ? 'new' : label
      // 下拉刷新不展示全局loading
      if(this.limit <= 10 && !this.bscrollLoading){
        this.$store.commit('Set_Loading_State', true)
      }
      queryAllArticle({ limit: limit, type:type, label: label }).then((res)=>{
        let tempArr = []
        this.total = res.data.total //数据库中返回的所有文章总数
        res.data.data.forEach((item)=>{
          let temp = {}
          temp.tid = item.tid
          temp.title = item.title
          temp.context = item.context
          temp.nickname = item.nickname
          if(item.miniAvatarUrl){
            temp.avatarUrl = item.miniAvatarUrl
          } else {
            temp.avatarUrl = require('../assets/images/avatar.png')
          }
          // 图片显示前三张
          let imgUrlArr = item.miniImgUrl ? item.miniImgUrl.split(',') : []
          temp.articleImg = {}
          if(this.$store.state.userAgent === 'Mobile'){
            if(imgUrlArr.length === 1 || imgUrlArr.length === 2){
              temp.articleImg.img_right = imgUrlArr[0]
              temp.articleImg.total = 1 // 显示1张
            } else if(imgUrlArr.length >= 3){
              temp.articleImg.img_right = imgUrlArr[2]
              temp.articleImg.img_center = imgUrlArr[1]
              temp.articleImg.img_left = imgUrlArr[0]
              temp.articleImg.total = 3 // 显示3张
            } else {
              temp.articleImg.total = 0 // 不显示图片
            }
          } else {
            if(imgUrlArr.length > 0){
              temp.articleImg.img_right = imgUrlArr[0]
              temp.articleImg.total = 1 // 显示1张
            } else {
              temp.articleImg.total = 0 // 不显示图片
            }
          }
          tempArr.push(temp)
        })
        this.lists = tempArr
        setTimeout(()=>{
          if(this.limit <= 10 && !this.bscrollLoading){
            this.$store.commit('Set_Loading_State', false)
          } else if(this.bscrollLoading){
            this.$refs.bscroll.done()
          }
        },0)
      })
    },
    getHotData(label){
      this.scroll = 0
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      let labelTemp = label
      if(label === 'hot' || label === 'new'){
        label = 'all'
      }
      if(labelTemp === 'new'){
        this.getData(this.limit, 'new', label)
      } else {
        this.getData(this.limit, 'hot', label)
      }
    },
    initLabel(){
      queryAllLabel().then(res=>{
        this.articleLabel = res.data.data.map(item => ({id: item.label_id, name: item.label_name}));
        this.articleLabelPC = this.articleLabel
        sessionStorage.setItem('labelArr', JSON.stringify(this.articleLabel))
      })
    },
    getNewData(label){
      this.scroll = 0
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      this.getData(this.limit, 'new', label)
    },
    logout(){
      this.isLogin = false
    },
    clearFlag(){
      this.goScrollTop()
      this.getData(this.limit, 'hot', 'all')
    },
    // 无限滚动加载页面
    handleScroll:debounce(function() {
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight // 可视区的高度
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop // 滚动条距离顶部的距离
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight //dom元素的高度，包含溢出不可见的内容
      //vue中获取滚动条到底部的距离
      let scrollBottom = scrollHeight - scrollTop - windowHeight
      // 关闭登录提示，优化用户体验
      // if(scrollTop - windowHeight  < 0 && this.$store.state.userAgent === 'Mobile'){
      //   this.showLoginReminder = false
      // }
      if(scrollTop - windowHeight > 0){
        this.showScrollTopBtn = true
      } else {
        this.showScrollTopBtn = false
      }
      //以下三个条件不执行数据加载：1.数据正在加载的状态，2.已经到底了，3.滚动条距离底部的距离小于100px
      if (!this.$store.state.loading && !this.isEnd && scrollBottom < 100 && this.lists.length < this.total) {
        this.limit += 10
        this.isEnd = true
      // 滚动到底部，弹出登录提醒
        if(!this.isLogin){
          this.showLoginReminder = true
        }
        this.getData(this.limit,this.listsType, this.label)
      } else {
        this.isEnd = false
      }
    }, 100),
    // 跳转前记住滚动条位置
    SaveScrollTop(){
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop // 滚动条距离顶部的距离
    },
    resetScrollTop(){
        // 滚动条不在顶部就滚动到顶部，在顶部就切换标签
        let scrollTopTemp = document.documentElement.scrollTop || document.body.scrollTop || document.body.parentNode.scrollTop || window.pageYOffset
        if(scrollTopTemp === 0){
          // hot和new类型传参需要转一下
          let labelTemp = this.label === 'all' ? 'hot' : this.label
          const index = this.articleLabel.findIndex(tag => tag.id === labelTemp);
          const container = document.querySelector('.classify');
          let nextTag;
          // 获取下一个标签
          if (index !== -1 && index < this.articleLabel.length - 1) {
            nextTag = this.articleLabel[index + 1];
            // 计算当前标签的位置并滚动到可见区域内
            const activeTag = document.querySelector('.label-focus');
            container.scrollLeft = activeTag.offsetLeft;
          } else {
            // 最后一个时回到第一个标签
            nextTag = this.articleLabel[0];
            container.scrollLeft = 0;
          }
          // hot和new类型传参需要转一下
          this.listsType = nextTag.id === 'hot' ? 'all' : nextTag.id
          labelTemp = nextTag.id === 'new' ? 'all' : nextTag.id
          // 调接口获取文章
          if(nextTag.id === 'hot'){
            this.getHotData(labelTemp)
          } else if(nextTag.id === 'new'){
            this.getNewData(labelTemp)
          } else {
            this.getData(this.limit, this.listsType, labelTemp)
          }
          // 确保高亮的标签始终在可视区域
        } else {
          document.body.scrollTop && document.body.scrollTo({top: 0, behavior: 'smooth'})
          document.documentElement.scrollTop && document.documentElement.scrollTo({top: 0, behavior: 'smooth'})
          this.scroll = 0
        }
    },
    goSearch(){
      this.scroll = 0
      this.$router.push({ path:'/search' })
    },
    goScrollTop(){
        document.body.scrollTop && document.body.scrollTo({top: 0, behavior: 'smooth'})
        document.documentElement.scrollTop && document.documentElement.scrollTo({top: 0, behavior: 'smooth'})
        this.scroll = 0
        this.$nextTick(()=>{
          this.showScrollTopBtn = false
        })
    },
    refresh(){
      this.bscrollLoading = true
      this.getData(this.limit, this.listsType, this.label)
    },
    bsLoading(){
      this.bscrollLoading = false
    },
    closeLoginReminder(){
      this.showLoginReminder = false
    },
    myBack() {
      history.pushState(null, null, document.URL);
      // 微信小程序返回配置
      wx.miniProgram.redirectTo({url: '/pages/index/index'})
    },
    wxSdk(){
          let url = seo.config.wxConfig.homePage.link + this.$route.fullPath
          let context = seo.config.wxConfig.homePage.desc
          wxSdk({ url:url }).then(res=>{
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: seo.config.wxConfig.appId, // 必填，公众号的唯一标识
              timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.data.signature,// 必填，签名
              jsApiList: ['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'] // 必填，需要使用的JS接口列表
            });
            wx.ready(function () {
              wx.checkJsApi({
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'], // 必填，需要使用的JS接口列表
                success: function(res) {
                  // “分享给朋友”及“分享到QQ”
                  if (res.checkResult.updateAppMessageShareData || res.checkResult.onMenuShareAppMessage) {
                    wx.updateAppMessageShareData({
                      title: seo.config.wxConfig.homePage.title, // 分享标题
                      desc: context, // 分享描述
                      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                      imgUrl:  seo.config.wxConfig.homePage.imgUrl, // 分享图标
                      success: function () {
                        // 设置成功
                      }
                    })
                  }
                  // “分享到朋友圈”及“分享到 QQ 空间”
                  if(res.checkResult.updateTimelineShareData || res.checkResult.onMenuShareTimeline){
                    wx.updateTimelineShareData({ 
                      title: seo.config.wxConfig.homePage.title, // 分享标题
                      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                      imgUrl: seo.config.wxConfig.homePage.imgUrl, // 分享图标
                      success: function () {
                        // 设置成功
                      }
                    })
                  }
              }})
            })
          })
    }
  },
  data:()=>{
    return {
      isLogin: false,
      inputVal:{
        placeholder: '搜索你想要的内容'
      },
      lists:[],
      findLists: [{
        id:'1',
        text: '有网友让我谈谈怎么向chatgpt靠拢...'
      },{
        id:'2',
        text: '大数据服务主要通过API接口以按次、按量、按时的收费模式进行销售'
      },{
        id:'3',
        text: 'ChatGPT正在引领一场深刻的变革...'
      },{
        id:'4',
        text: '钉钉发布新产品并现场演示接入阿里通义千问大模型'
      },{
        id:'5',
        text: 'GPT-3.5 是由 OpenAI 在 2022年6月发布的人工智能文本生成模型'
      },{
        id:'6',
        text: '选择一个细分领域，以期待未来几年被大厂收购是一个比较冒险的想法'
      },{
        id:'7',
        text: 'auto-gpt是一个由中国开发者发起的、基于GPT模型的自然语言生成项目'
      },{
        id:'8',
        text: 'OpenAI是一家非营利性研究机构，其目标是推动人工智能的发展并推动AI技术的广泛应用'
      },{
        id:'9',
        text: '云计算、大数据、人工智能这几个服务详细说一下...'
      },{
        id:'10',
        text: '作为GPT-3.5模型，我是使用了一个非常庞大的数据集进行训练的，准确的数字是1750B个参数'
      }],
      limit: 10,
      total: 0, // 数据库中返回的所有文章总数
      isEnd: false,
      scroll: 0,
      swiperList:[
        '愿景与目标',
        '前往个人主页',
        '草稿箱',
        '创建文章',
        '退出登录',
        '搜索你想要的内容'
      ],
      articleLabel: '',
      articleLabelPC: '',
      bscrollLoading:false, // 用于下拉刷新不展示全局loading
      showLoginReminder: false,
      listsType: 'hot', // 文章类型
      showScrollTopBtn: false, // 回到顶部按钮
      isFocusAIGC: seo.config.appId === 'mediahelper' ? true : false, // 小天AI目前仅限媒辅助开放
      beianId: seo.config.beianId,
      appId: seo.config.appId, // 自定义营销二维码
      qrCode:{
        souyisou: require('../assets/images/qrcode-wechat.png'),
        wxqun:'',
        kefu:'',
        wxgzh:'',
      }
    }
  },
  activated(){
    this.wxSdk()
    if(this.isLogin){
      this.showLoginReminder = false
    }
    history.pushState(null, null, document.URL); 
    window.addEventListener("popstate", this.myBack, false);
    // 从文章详情返回首页，滚动到上次位置
    this.scrollTime = setTimeout(() => {
      document.addEventListener('scroll',this.handleScroll);
      document.documentElement.scrollTop = this.scroll
      document.body.scrollTop = this.scroll
		}, 0)
  }
}
</script>

<style scoped lang="scss">
.search {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  max-width: 1150px;
  margin: 0 auto;
  .fake-input {
    width: calc(100% - 26px);
    border: 2px solid #eee;
    box-sizing: border-box;
    margin: 10px 5px 0px 10px;
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    color: #78788e;
    // max-width: 625px;
  }
  input {
    width: 100%;
    border-radius: 15px;
    height: 35px;
    border: 1px solid #eee;
    margin: 10px 10px 0;
    padding-left: 40px;
    box-sizing: border-box;
    -webkit-appearance: none;
  }
  .searchImg {
    position: absolute;
    left: 25px;
    top: 21px;
  }
  .createImg {
    margin: 12px 10px 0 0;
    min-width: 48px;
  }
  .loginImg {
    margin: 12px 10px 0 0;
    min-width: 48px;
  }
}
.classify {
  display: flex;
  padding: 8px 0 8px 20px;
  margin: 0 auto;
  height: 50px;
  max-height: 50px;
  max-width: 1150px;
  box-sizing: border-box;
  background: #f3f3f3;
  position: fixed;
  z-index: 999;
  top: 50px;
  left: 0px;
  right: 0px;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+, edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .label {
    border: .5px solid #e1e1e1;
    display: flex;
    align-items: center;
    background: #eaeaea;
    font-size: 16px;
    padding: 5px 10px;
    min-height: 30px;
    font-weight: 600;
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 10px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .label-focus {
    border: .5px solid #e1e1e1;
    display: flex;
    align-items: center;
    background: #eaeaea;
    font-size: 16px;
    padding: 5px 10px;
    min-height: 30px;
    font-weight: 600;
    box-sizing: border-box;
    margin-right: 10px;
    border-radius: 3px;
    color: #ec745b;
    transition: all 0.2s ease-out;
    flex-shrink: 0;
    cursor: pointer;
  }
}

.classify-pc {
  width: 1150px;
  left: 0px;
  right: 0px;
  position: fixed;
  z-index: 999;
  top: 65px;
  margin: 0 auto;
  // box-sizing: border-box;
  // background: #fefefe;
  display: flex;
  .row {
    display: flex;
    height: 50px;
    max-height: 50px;
    padding: 0px 15px;
    margin-right: 32px;
    width: 100%;
    // box-sizing: border-box;
    background: #fefefe;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+, edge */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .label {
      border-bottom: 2px solid #fefefe;
      display: flex;
      align-items: center;
      background: #fefefe;
      font-size: 16px;
      padding: 5px 0px;
      min-height: 30px;
      font-weight: 600;
      box-sizing: border-box;
      margin: 0 15px 0 5px;
      flex-shrink: 0;
      cursor: pointer;
    }
    .label-focus {
      border-bottom: 2px solid #ec745b;
      display: flex;
      align-items: center;
      background: #fefefe;
      font-size: 16px;
      padding: 5px 0px;
      margin: 0 15px 0 5px;
      min-height: 30px;
      font-weight: 600;
      box-sizing: border-box;
      // margin-right: 10px;
      color: #ec745b;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}

.home_wrap {
  width: 100%;
  margin: 0;
}
.load-more {
  text-align: center;
  color: #969699;
  margin-bottom: calc(65px + constant(safe-area-inset-bottom));
  margin-bottom: calc(65px + env(safe-area-inset-bottom));
  font-size: 14px;
}
.load-more-bottom {
  text-align: center;
  color: #969699;
  margin: 10px 0 0 0;
  margin-bottom: calc(10px + constant(safe-area-inset-bottom));
  margin-bottom: calc(10px + env(safe-area-inset-bottom));
  font-size: 14px;
}
.xyScrollBar {
  overflow: scroll;
  max-height: 850px;
  min-height: 850px;
}
.login-reminder {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 55px;
  height: 50px;
  line-height: 50px;
  max-width: 1150px;
  background: rgba(56,58,71,0.9);
  z-index: 1000;
  color: #fff;
  text-align: left;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  .tips {
    display: flex;
    align-items: center;
    .cancel {
      margin-right: 10px;
      color: #fff;
    }
    .text {
    }
  }
  .login {
    display: flex;
    align-items: center;
    .login-buttton {
      color: #fff;
      background: #ec745b;
      height: 28px;
      padding: 0px 20px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      font-size: 15px;
    }
  }
}
.beian {
    text-align: left;
    height: 30px;
    font-size: 14px;
    margin: 0 5px;
    img {
        margin-right: 5px;
    }
    a {
        color: #969699;
    }
    .row {
        display: flex;
        align-items: center;
    }
}
.copyright {
    color: #969699;
    display: flex;
    align-items: center;
    margin: 5px 0;
    cursor: default;
    .logo {
        font-size: 14px;
        height: 20px;
        display: flex;
        align-items: center;
    }
}
.card {
    text-align: left;
    // margin: 50px 20px 20px;
    .title {
        font-weight: 600;
        font-size: 16px;
        margin: 10px 5px 15px;
        cursor: default;
    }
    .wrap {
        background: #fff;
        margin: 10px 0;
        padding: 10px 0;
        .panel {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;
            cursor: default;
            // border: 1px solid #f3f3f3;
            .span {
                display: flex;
                align-items: center;
                display: -webkit-box;
                -webkit-box-orient: vertical;    
                -webkit-line-clamp: 1;    
                overflow: hidden;
            }
        }
    }
}
.fixed-button {
  position: fixed;
  right: 50px;
  bottom: 50px;
  .default-scroll-top {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }
  .default-scroll-top:hover {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
}
.chat-robot {
  position:fixed;
  right: 10px;
  bottom: 80px;
  padding: 0px 1px;
  // background: rgba(243, 243, 243, 0.8);
  border-radius: 50%;
}
</style>
