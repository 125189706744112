<!-- 上下滚动效果 -->
<template>
    <div class="demo01">
      <div class="content">
          <ul :class="{marquee_top:animate}">
            <li :key="index" v-for="(item, index) in lists">{{item}}</li>
          </ul>
      </div>
    </div>
</template>

<script>
export default {
    name: 'Demo01',
    data () {
        return {
            animate: false,
        }
    },
    props:{
        lists:{
            type: Array,
            default: ()=>[]
        }
    },
    created: function () {
        this.showMarquee()
        setInterval(this.showMarquee, 2000)
    },
    methods: {
        showMarquee(){
            this.animate = true
            setTimeout(() => {
                this.animate = false
                this.lists.push(this.lists[0])
                this.lists.shift()
            }, 500)
        }
    }
}
</script>

<style scoped>
.marquee_top {
  transition: all .5s;
  margin-top: -33px
}
.demo01 {
  width: 100%;
  height: 33px;
  background: none;
  overflow: hidden;
  text-align: left;
}
.demo01 ul {
  list-style: none;
}
.demo01 ul li {
  line-height: 33px;
  color: #78788e;
}
.demo01 .content {
  display: flex;
}
</style>